import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <Layout formTitle="Let us know how we can help with your dream log home.">
      <Seo 
        title="About"
        description="A family owned and operated log home design and lumber mill located in Vermont. We manufacture quality custom log homes and dimensional milled lumber." 
      />
      <div className="container-xl mx-auto pt-32 md:pt-48">
        <StaticImage
          src="../images/ALHOV_about.jpg" 
          alt="Aerial shot of a forest"
          placeholder="blurred"
          objectFit="contain"
        />
      </div>
      <div className="container mx-auto mt-16 md:mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
          <h1>About Authentic Log Homes of Vermont</h1>
          <div className="md:mt-4">
            <p>Our family owned and operated business designs complete log home packages, customized to your specifications. Authentic Log Homes has designed hundreds of homes for folks all over the Northeast. We're confident that we can design one for you.</p>
            <p>We build from the floor plan forward producing all the materials necessary for a home that will last for hundreds of years.</p>
            <p>Authentic Log Homes uses native Northern White Cedar logs. All of our timber is harvested locally in the Northeast Kingdom of Vermont by local independent loggers.</p>
            <p className="mb-0">We use a "D" shaped profile for our logs that result in a flat, flush interior surface and a rounded traditional exterior. Our logs are "draw-shaved" by hand. Each log retains its individual surface characteristics making every Authentic Log home we sell unique in appearance and value.</p>
          </div>
        </div>
      </div>
      <div className="relative bg-color py-32 mt-32 md:mt-48">
        <div className="container mx-auto text-center">
          <h2>Our Promise</h2>
          <p className="max-w-3xl mx-auto md:text-2xl md:leading-10">Using logs as the primary component of house construction is a time honored tradition. Authentic Log Homes is your key to affordable, comfortable, and energy efficient living.</p>
        </div>
      </div>
    </Layout>
  );
}
 
export default About;